import {callUrl} from "./api";

export default {
    async getTauxTvas(data = {}) {
        try {
            let res = await callUrl("GET", 'api/taux_tvas', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getDecStatuts() {
        try {
            let res = await callUrl("GET", 'api/dec_statuts')
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getPointGestionStatuts() {
        try {
            let res = await callUrl("GET", 'api/point_gestion_statuts')
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getAffaireStatuts() {
        try {
            let res = await callUrl("GET", 'api/affaire_statuts')
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getAffaireTypes(data = {}) {
        try {
            let res = await callUrl("GET", 'api/affaire_types', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getComptabiliteTypes(data = {}) {
        try {
            let res = await callUrl("GET", 'api/comptabilite_types', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getAffaireType(affaire_typeId) {
        try {
            let res = await callUrl("GET", `api/affaire_types/${affaire_typeId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async patchAffaireType(affaire_typeId, data) {
        return await callUrl("PATCH", `api/affaire_types/${affaire_typeId}`, data);
    },

    async deleteAffaireType(affaire_typeId) {
        return await callUrl("DELETE", `api/affaire_types/${affaire_typeId}`);
    },

    async addAffaireType(data) {
        return await callUrl("POST", `api/affaire_types`, data);
    }
}