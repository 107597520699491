import tableauGestionApi from "@/api/tableauGestionApi"

export async function confirmDeleteTableauGestion(tableauGestionId, tableauGestionNom, deleteCallback = null) {
    let {isConfirmed} = await this.$swal({
        title: 'Supprimer TableauGestion',
        text: `Etes-vous sûr de vouloir supprimer le Tableau de Gestion ${tableauGestionNom}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: "Annuler",
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    })

    if(isConfirmed)
        await this.deleteTableauGestion(tableauGestionId, deleteCallback);
}

export async function deleteTableauGestion(tableauGestionId, deleteCallback = null) {
    if(this.hasOwnProperty("deleting"))
        this.deleting = true;
    try {
        await tableauGestionApi.deleteTableauGestion(tableauGestionId);
        this.showToast("success", "Tableau Gestion supprimé", "CheckIcon")
        if(deleteCallback != null && typeof deleteCallback === "function")
            deleteCallback(tableauGestionId);
        
    } catch(e) {
        console.log("e", e)
        this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
    }
    finally {
        if(this.hasOwnProperty("deleting"))
            this.deleting = false;
    }
}