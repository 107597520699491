import {callUrl} from "./api";

export default {
    async getTableauxGestion(data = {}) {
        try {
            let res = await callUrl("GET", 'api/tableau_gestions', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getTableauGestion(tableauGestionId) {
        try {
            let res = await callUrl("GET", `api/tableau_gestions/${tableauGestionId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async patchTableauGestion(tableauGestionId, data) {
        return await callUrl("PATCH", `api/tableau_gestions/${tableauGestionId}`, data);
    },

    async deleteTableauGestion(tableauGestionId) {
        return await callUrl("DELETE", `api/tableau_gestions/${tableauGestionId}`);
    },

    async addTableauGestion(data) {
        return await callUrl("POST", `api/tableau_gestions`, data);
    },
}